<template>
  <div :class="isColumn ? 'flex-column' : 'd-flex' " class="mt-3 pl-0">
    <div :class="isColumn ? '' : 'pl-0 col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2'">
      <span class="tag-label-text">{{ label }}</span>
    </div>

    <div class="d-flex text-area-field">
      <ClEditor
        ref="textArea"
        @change="onEditorChange"
        :is-toolbar="isToolbar"
        :value-prop="txt"
        :placeholder="placeholder"
        :disabled="disabled"
        :name="name"
      />
      <IconWithTooltip :text="tooltip" class="ml-2"/>
    </div>

    <div class="zq-required-message">{{ requiredText }}</div>
  </div>
</template>

<script>
import ClEditor from '@/shared/components/formComponents/ClEditor';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';

export default {
  components: {
    IconWithTooltip,
    ClEditor
  },
  props: {
    isColumn: {
      type: Boolean,
      default: false
    },
    value: [String, Number, Array],
    label: String,
    tooltip: String,
    placeholder: String,
    disabled: Boolean,
    isToolbar: Boolean,
    name: {
      type: String,
      default: '',
    },
    requiredText: {
      type: String,
      default: 'Required',
    },
  },
  data() {
    return {
      txt: null,
    };
  },
  created() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        this.txt = this.value.join(', ');
      } else {
        this.txt = this.value;
      }
    }
  },
  watch: {
    value(n, o) {
      if (n !== o) {
        this.txt = this.value;
      }
    },
    txt(val) {
      if (val && val.length && this.$refs.textArea) {
        this.$refs.textArea.$el.classList.remove('zq-invalid');
      }

      this.$emit('input', this.txt);
    },
  },
  methods: {
    onEditorChange(html) {
      this.txt = this.replaceClassesWithInlineStyles(html);
    },
    replaceClassesWithInlineStyles(html) {
      const div = document.createElement('div');
      div.innerHTML = html;

      div.querySelectorAll('.ql-align-center').forEach(el => {
        el.style.textAlign = 'center';
      });

      div.querySelectorAll('.ql-align-right').forEach(el => {
        el.style.textAlign = 'right';
      });

      div.querySelectorAll('.ql-align-justify').forEach(el => {
        el.style.textAlign = 'justify';
      });

      return div.innerHTML;
    }
  },
};
</script>

<style lang="scss">
.zq--checkbox--wrap {
  .zq--checkbox {
    display: flex;
    align-items: center;
  }

  .toggle__text {
    line-height: normal;
  }

  .toggle__label {
    display: flex;
    align-items: center;
    margin-left: 0.2rem;
  }
}

.zq-required-message {
  color: var(--zq-warn);
  font-size: 12px;
  display: none;
}

.zq-invalid {
  .editr--content {
    border: solid 1px var(--zq-warn);
  }
}

.zq-invalid + .zq-required-message {
  display: block;
}
</style>
